import type { Header } from '~/components/ui/data/DataTable.vue';

export const useZoneTable = () => {
  // Composables
  const { t } = useI18n();

  const headers: Header[] = [
    {
      key: 'name',
      label: t('explore.zone.name'),
    },
    {
      key: 'consumption',
      label: t('explore.consumption.consumption'),
    },
    {
      key: 'top_level_percentage',
      label: t('explore.consumption.pourcent_of_total'),
    },
    {
      key: 'parent_percentage',
      label: t('explore.consumption.pourcent_of_parent'),
    },
  ];

  return { headers };
};
