<script setup lang="ts">
// Composables
const { formatNumberToIsoNumber } = useNumbers();
const { headers } = useZoneTable();

// Store
const { getZoneResponse } = storeToRefs(zoneStore());
const { loading } = storeToRefs(exploreStore());
</script>
<template>
  <ui-data-table :items="getZoneResponse?.areas ?? []" :headers="headers" :loading="loading">
    <template #item-name="{ item }"> {{ item.label }} </template>
    <template #item-consumption="{ item }"> {{ formatNumberToIsoNumber(item.consumption.quantity) }} kWh </template>
    <template #item-top_level_percentage="{ item }">
      <ui-badge v-if="item.top_level_percentage" color="blue">{{ formatNumberToIsoNumber(item.top_level_percentage, 2) }} % </ui-badge>
      <span v-else>--</span>
    </template>
    <template #item-parent_percentage="{ item }">
      <ui-badge v-if="item.parent_percentage" color="blue">{{ formatNumberToIsoNumber(item.parent_percentage, 2) }} % </ui-badge>
      <span v-else>--</span>
    </template>
  </ui-data-table>
</template>
