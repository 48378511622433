import dayjs from 'dayjs';

export const useZoneApi = () => {
  // Composable
  const { client } = useApiV2();

  // Store
  const { getPeriod, getEnergyType, getAreaId } = storeToRefs(exploreStore());
  const { setLoading } = exploreStore();
  const { setZoneResponse, setZoneError } = zoneStore();
  const { max_depth } = storeToRefs(zoneStore());

  // Data
  const abortConsumptionController = ref<AbortController | null>(null);
  const currentRequestId = ref<string | null>(null);

  // Methods
  // ============ Get Consumption for one area ============
  const getZoneConsumption = async () => {
    const requestId = dayjs().toString();
    currentRequestId.value = requestId;

    if (abortConsumptionController.value) {
      abortConsumptionController.value.abort();
    }

    try {
      abortConsumptionController.value = new AbortController();
      setZoneResponse(null);
      setZoneError(null);
      setLoading(true);

      const reponse = await client.GET('/v2/areas/{area_id}/explore/subareas', {
        params: {
          query: {
            energy_type: getEnergyType.value,
            start: dayjs(getPeriod.value.start).format('YYYY-MM-DD HH:mm'),
            end: dayjs(getPeriod.value.end).format('YYYY-MM-DD HH:mm'),
            max_depth: max_depth.value,
          },
          path: {
            area_id: getAreaId.value,
          },
        },
        signal: abortConsumptionController.value?.signal,
      });

      if (reponse.data.data) {
        setZoneResponse(reponse.data.data);
      } else {
        setZoneError(reponse.data.error);
        setZoneResponse(null);
      }
      setLoading(false);
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  };

  return { getZoneConsumption };
};
