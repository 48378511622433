import localforage from 'localforage';

export const useZoneStorage = () => {
  // Stores
  const { selectedClient, selectedSite } = storeToRefs(globalStore());
  const { max_depth } = storeToRefs(zoneStore());
  const { setMaxDepth } = zoneStore();

  const getFiltersFromStorage = async () => {
    // Aggregation levels
    const depthFromStorage = await localforage.getItem<number>(`nrjx-${selectedClient.value}-${selectedSite.value[0]}-zone-depth`);
    if (depthFromStorage) {
      setMaxDepth(depthFromStorage);
    }
  };

  const setFiltersInStorage = () => {
    localforage.setItem(`nrjx-${selectedClient.value}-${selectedSite.value[0]}-zone-depth`, max_depth.value);
  };

  return {
    getFiltersFromStorage,
    setFiltersInStorage,
  };
};
