<script setup lang="ts">
// Store
const { getLoading } = storeToRefs(exploreStore());
const { getZoneResponse } = storeToRefs(zoneStore());

const areaWithConsumption = computed(() => {
  return getZoneResponse.value?.areas.map((area) => {
    return {
      id: area.id,
      name: area.label,
      parent_id: area.parent_id,
      consumption: area.consumption.quantity,
      top_level_percentage: area.top_level_percentage,
      parent_percentage: area.parent_percentage,
    };
  });
});
</script>
<template>
  <ui-chart-area-sankey :loading="getLoading" :area-with-consumption="areaWithConsumption" />
</template>
