<script setup lang="ts">
// Components
import ChartByZone from '~/app-modules/explore/components/chart/byZone.vue';
import ZoneTable from '../../components/table/zone.vue';

// Composables
const { getZoneConsumption } = useZoneApi();
const { getKeysFromForage, setKeysInForage } = useExploreStorage();
const { getFiltersFromStorage, setFiltersInStorage } = useZoneStorage();
// Store
const { getAreaId, getPeriod, getEnergyType, chartType } = storeToRefs(exploreStore());
const { max_depth } = storeToRefs(zoneStore());

// Data
const watcher = ref();

// Lifecycle
onMounted(async () => {
  await getKeysFromForage();
  await getFiltersFromStorage();

  // Watch change values after the first call
  if (chartType.value === 'explore-by-zone') {
    watcher.value = watch(
      [() => getAreaId.value, () => getPeriod.value, () => getEnergyType.value, () => max_depth.value],
      async () => {
        await setKeysInForage();
        await setFiltersInStorage();

        await getZoneConsumption();
      },
      { deep: true },
    );
  }

  await getZoneConsumption();
});

onUnmounted(() => {
  if (watcher.value) {
    watcher.value();
  }
});
</script>
<template>
  <div class="w-full flex-1 flex flex-col gap-4">
    <div class="w-full h-full bg-white border border-gray-200 rounded-lg p-1">
      <chart-by-zone />
    </div>
    <ui-card class="w-full h-full">
      <zone-table />
    </ui-card>
  </div>
</template>
